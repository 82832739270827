/**
*  Module
*
* Description
*/
var myApp = angular.module('myApp', ['ngFileUpload', 'summernote', 'ngSanitize', 'ui.bootstrap','ksSwiper']);
myApp.run(['$http', function ($http) {
    $http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    $http.defaults.headers.common['Accept'] = 'application/json';
}]);
myApp.filter('unsafe', function($sce) { return function(htmlCode){
    return $sce.trustAsHtml(htmlCode);
}
});
